import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
    <Layout>
        <SEO title="about" />
        <h3>about me</h3>
        <p>
            I'm currently working as a software engineer at Atomwise.
        </p>
        <p>
            My friends joke that my hobby is hobbies since I'll jump around from one thing
            to another but some that have stuck around are programming, cooking, and climbing.
        </p>

        <p>
          Recently, I've enjoyed <Link to={`/blog/proud-of-2019-proteomics`}>learning about biology</Link>, playing board games, going to music festivals, watching the NBA, and making ramen (no not the instant noodle pack). I like to learn so I'm pretty open to trying new things.
        </p>
        
        <p>
            In no particular order, the most important things to me are doing good work,
            spending time with friends and family, and making time for my hobbies.
        </p>
        <p>🙂</p>
        <p>contact: eric.hjoon.song at gmail.com</p>
    </Layout>
)

export default AboutPage
